import {
  FC,
  useEffect,
  useState,
  useRef,
  RefObject,
  useMemo,
  useCallback,
  ChangeEvent,
} from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useLocalstorageState } from 'rooks'
import { useQuery } from 'urql'
import { isArray, isEmpty } from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import { loader } from 'graphql.macro'
import Skeleton from 'react-loading-skeleton'

import MDashHead from 'src/pages/elements/MDashHead'
import Container from 'src/components/01-atoms/Container'
import Grid from 'src/components/01-atoms/Grid'
import Alert from 'src/components/01-atoms/Alert'
import ResultsPicker from 'src/components/01-atoms/ResultsPicker'
import Pager from 'src/components/01-atoms/Pager'
import SimpleSearch from 'src/components/01-atoms/SimpleSearch'
import PackageListTable from 'src/components/03-organisms/PackageListTable'

import ManifestHeader from 'src/pages/elements/ManifestHeader'
import Aggregates from 'src/pages/elements/Aggregates'
import ManifestActions from 'src/pages/elements/ManifestActions'
import PackagesContext from 'src/contexts/PackagesContext'

import useAppParams from 'src/utils/hooks/useAppParams'
import useSet from 'src/utils/hooks/useSet'

import { IManifestPackageSort, IShippingLabelStatus } from 'src/graphql/types'
import { IGetManifestPackagesQueryVariables } from 'src/graphql/queries/getManifestPackages.types'
import {
  IGetManifestOrderTypesQuery,
  IGetManifestOrderTypesQueryVariables,
} from 'src/graphql/queries/getManifestOrderTypes.types'
import useGetManifestPackages from 'src/utils/hooks/useGetManifestPackages'
import { useGetManifestCombinedAggregates } from 'src/utils/hooks/useGetManifestCombinedAggregates'
import {
  IGetPurchaserInfoQuery,
  IGetPurchaserInfoQueryVariables,
} from 'src/graphql/queries/getPurchaserInfo.types'
import { PackageListPackage } from 'src/utils/types/PackageListPackage'
import { packageIsSelectable } from 'src/utils/helpers/package'
import {
  IGetManifestFacilitiesQuery,
  IGetManifestFacilitiesQueryVariables,
} from 'src/graphql/queries/getManifestFacilities.types'
import {
  IGetManifestCorporateOrdersQuery,
  IGetManifestCorporateOrdersQueryVariables,
} from 'src/graphql/queries/getManifestCorporateOrders.types'
import useMultiFacilityParams from 'src/utils/hooks/useMultiFacilityParams'
import { useShiftSelect } from 'src/utils/hooks/useShiftSelect'
import { useGetCustomerChoices } from 'src/utils/hooks/useGetCustomerChoices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

const getManifestOrderTypes = loader( 'src/graphql/queries/getManifestOrderTypes.graphql' )
const getManifestCorporateOrders = loader( 'src/graphql/queries/getManifestCorporateOrders.graphql' )
const getManifestPurchaserInfo = loader( 'src/graphql/queries/getPurchaserInfo.graphql' )
const getManifestFacilities = loader( 'src/graphql/queries/getManifestFacilities.graphql' )

const Manifest: FC = () => {
  const params = useParams()
  const {
    userId,
    mdashAccountId,
    mdashAccountPermissions,
    pauseForFacility,
    canUseMultiFacility,
    facilityId,
    facilityType,
    toShipOn,
    toShipOnAsString,
    packageSort,
    setPackageSort,
    makeLinkUrls,
    isToShipOnANonShippingDay,
    isAdmin,
  } = useAppParams()
  const { canQueryPurchaserInfo } = mdashAccountPermissions || {}
  const [ isCopied, setIsCopied ] = useState( false )
  const selectAllRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>
  const [ tableSearchValue, setTableSearchValue ] = useState<string>( '' )
  const localStorageKey = 'mdx-manifest-num-per-page'
  const localStorageDefault = 100
  const [ numPerPage ] = useLocalstorageState( localStorageKey, localStorageDefault )
  const [ page, setPage ] = useState( 0 )
  const form = useForm<IGetManifestPackagesQueryVariables>({
    defaultValues: {
      labelPrintStatus: IShippingLabelStatus.TO_SHIP,
      productExclusion: false,
    },
  })
  const { watch, getValues } = form
  const { productCountInPackages, ...nonEmptyFormValues } = Object.fromEntries(
    Object.entries( getValues()).filter(([ , v ]) => !isEmpty( v ) || v === true )
  )

  const manifestQueryVariables = {
    mdashAccountId,
    toShipOn: toShipOnAsString,
    /*
      This looks yucky, but I just couldn't figure out why the form plugin wasn't casting it as
      an integer. The form plugin does supply a `valueAsNumber` option, but as of this work, it
      only works for text inputs. Additionally, using parseInt onChange prevented the checkbox
      from being turned on because the checkbox value is a string.
      https://react-hook-form.com/api/useform/register#:~:text=%7D%0A/%3E-,valueAsNumber,-%3A%0Aboolean
    */
    productCountInPackages: isArray( productCountInPackages )
      ? productCountInPackages?.map(( v: string | number ) => Number( `${v}` ))
      : parseInt( `${productCountInPackages}`, 10 ),
    ...nonEmptyFormValues,
    query: tableSearchValue,
    facilityId,
    facilityType,
  }

  const [{ error: packagesError, data: manifestPackages, fetching: fetchingPackages }] =
    useGetManifestPackages({
      variables: { ...manifestQueryVariables, ...packageSort, page, limit: numPerPage },
      requestPolicy: 'cache-and-network',
      pause: Number( mdashAccountId || 0 ) < 1 || pauseForFacility,
    })

  const packageIds: string[] =
    manifestPackages?.manifestPackages?.packages?.collection.map(( x ) => x.id ) || []

  const copyablePackageIds = useMemo(
    () => `suborder_ids = [${packageIds.join( ', ' )}];`,
    [ packageIds ]
  )

  const [{ error: orderTypesError, data: manifestOrderTypes, fetching: fetchingOrderTypes }] =
    useQuery<IGetManifestOrderTypesQuery, IGetManifestOrderTypesQueryVariables>({
      query: getManifestOrderTypes,
      variables: {
        ...manifestQueryVariables,
        ...packageSort,
        packageIds,
        facilityId,
        facilityType,
      },
      requestPolicy: 'cache-and-network',
      pause:
        Number( mdashAccountId || 0 ) < 1 ||
        fetchingPackages ||
        Number( manifestPackages?.manifestPackages?.packages?.collection.length || 0 ) < 1,
    })

  const [
    {
      error: corporateOrdersError,
      data: manifestCorporateOrders,
      fetching: fetchingCorporateOrders,
    },
  ] = useQuery<IGetManifestCorporateOrdersQuery, IGetManifestCorporateOrdersQueryVariables>({
    query: getManifestCorporateOrders,
    variables: {
      ...manifestQueryVariables,
      ...packageSort,
      packageIds,
      facilityId,
      facilityType,
    },
    requestPolicy: 'cache-and-network',
    pause:
      Number( mdashAccountId || 0 ) < 1 ||
      fetchingPackages ||
      Number( manifestPackages?.manifestPackages?.packages?.collection.length || 0 ) < 1,
  })

  const {
    data: manifestProductOptions,
    error: productOptionsError,
    fetching: fetchingProductOptions,
  } = useGetCustomerChoices({ packageIds })

  const [
    { error: purchaserInfoError, data: manifestPurchaserInfo, fetching: fetchingPurchaserInfo },
  ] = useQuery<IGetPurchaserInfoQuery, IGetPurchaserInfoQueryVariables>({
    query: getManifestPurchaserInfo,
    variables: {
      mdashAccountId,
      toShipOn: toShipOnAsString,
      packageIds,
    },
    requestPolicy: 'cache-and-network',
    pause:
      Number( mdashAccountId || 0 ) < 1 ||
      fetchingPackages ||
      Number( manifestPackages?.manifestPackages?.packages?.collection.length || 0 ) < 1 ||
      !canQueryPurchaserInfo,
  })

  const [{ error: facilitiesError, data: manifestFacilities, fetching: fetchingFacilities }] =
    useQuery<IGetManifestFacilitiesQuery, IGetManifestFacilitiesQueryVariables>({
      query: getManifestFacilities,
      variables: {
        mdashAccountId,
        toShipOn: toShipOnAsString,
        packageIds,
      },
      requestPolicy: 'cache-and-network',
      pause:
        facilityId !== null ||
        Number( mdashAccountId || 0 ) < 1 ||
        fetchingPackages ||
        Number( manifestPackages?.manifestPackages?.packages?.collection.length || 0 ) < 1,
    })

  const {
    aggregates,
    labelAggregates,
    facilityAggregates,
    fetchingLabelAggregates,
    refetchLabelAggregates,
    fetchingProductAggregates,
    refetchProductAggregates,
    fetchingOrderTypeAggregates,
    refetchOrderTypeAggregates,
    fetchingCorporateOrderAggregates,
    refetchCorporateOrderAggregates,
    fetchingAuxiliaryAggregates,
    refetchAuxiliaryAggregates,
    fetchingFacilityAggregates,
    refetchFacilityAggregates,
    error: aggregatesError,
    errorMessage: aggregatesErrorMessage,
  } = useGetManifestCombinedAggregates({ variables: manifestQueryVariables })

  const refetchManifestData = () => {
    refetchLabelAggregates()
    refetchProductAggregates()
    refetchOrderTypeAggregates()
    refetchCorporateOrderAggregates()
    refetchAuxiliaryAggregates()
    refetchFacilityAggregates()
  }

  const pageCount = manifestPackages?.manifestPackages?.packages?.metadata.totalPages ?? 1

  const packages =
    manifestPackages?.manifestPackages?.packages &&
    manifestPackages?.manifestPackages?.packages?.collection
  const orderTypes = manifestOrderTypes?.manifestPackages.orderTypes
  const corporateOrders = manifestCorporateOrders?.manifestPackages.corporateOrders
  const facilities = manifestFacilities?.manifestPackages.facilities
  const hasPackages = packages && packages.length > 0
  const totalOrders =
    manifestPackages?.manifestPackages?.packages &&
    manifestPackages?.manifestPackages?.packages?.metadata.totalCount

  const { shouldDisplayFacilityAssignmentButtons: canAssignLocation } = useMultiFacilityParams({
    facilityAggregates: facilityAggregates || [],
  })
  const isAbstractFacility = canUseMultiFacility && Number( facilityId || 0 ) < 1
  const eligiblePackages = useMemo(
    () =>
      packages?.filter(( x ) => packageIsSelectable( x, isAbstractFacility, canAssignLocation )) || [],
    [ packages, isAbstractFacility, canAssignLocation ]
  )

  const {
    set: selectedPackages,
    reset: deselectAllPackages,
    addMultiple: selectPackages,
    removeMultiple: deselectPackages,
  } = useSet<string>( new Set())

  const resetSelection = useCallback(() => {
    deselectAllPackages()
    if ( isEmpty( selectAllRef ) || !selectAllRef?.current ) return
    selectAllRef.current.checked = false
  }, [ deselectAllPackages ])

  const ids =
    packages?.filter(( pkg ) => packageIsSelectable( pkg, isAbstractFacility )).map(( x ) => x.id ) || []
  const onChange = useShiftSelect( ids, selectPackages, deselectPackages )

  const resetAndGoToFirstPage = () => {
    setPage( 0 )
    resetSelection()
  }

  const resetManifest = () => {
    resetAndGoToFirstPage()
    refetchManifestData()
  }

  useEffect(() => {
    resetAndGoToFirstPage()
  }, [ numPerPage, toShipOn, tableSearchValue, facilityId, facilityType ])

  useEffect(() => {
    const subscription = watch(() => {
      resetAndGoToFirstPage()
    })
    return () => subscription.unsubscribe()
  }, [ watch ])

  useEffect(() => {
    resetSelection()
  }, [ page ])

  useEffect(() => {
    setTableSearchValue( '' )
  }, [ toShipOn ])

  useEffect(() => {
    if ( isEmpty( selectAllRef ) || !selectAllRef?.current ) return
    selectAllRef.current.indeterminate =
      selectedPackages.size > 0 && selectedPackages.size < eligiblePackages.length
    selectAllRef.current.checked =
      eligiblePackages.length > 0 && eligiblePackages.length === selectedPackages.size
    selectAllRef.current.disabled = eligiblePackages.length === 0
  }, [ eligiblePackages, selectedPackages ])

  const packagesContextValue = useMemo(
    () => ({
      packages: manifestPackages?.manifestPackages?.packages?.collection as PackageListPackage[],
    }),
    [ manifestPackages ]
  )

  if (
    !isAdmin &&
    !!userId &&
    !!mdashAccountId &&
    ( params.mdashAccountId !== mdashAccountId || isToShipOnANonShippingDay )
  ) {
    return <Navigate to={makeLinkUrls().manifest} replace />
  }

  return (
    <>
      <MDashHead pageTitle="Manifest" />
      <FormProvider {...form}>
        <ManifestHeader
          labelAggregates={labelAggregates || []}
          fetchingLabelAggregates={fetchingLabelAggregates}
          facilityAggregates={facilityAggregates || []}
          fetchingFacilityAggregates={fetchingFacilityAggregates || fetchingPackages}
        />
        <Container className="py-6">
          <Grid>
            {( aggregatesError ||
              packagesError ||
              orderTypesError ||
              corporateOrdersError ||
              productOptionsError ||
              purchaserInfoError ||
              facilitiesError ) && (
              <div className="col-span-12">
                <Alert type="error">
                  {aggregatesErrorMessage ||
                    packagesError?.message ||
                    orderTypesError?.message ||
                    corporateOrdersError?.message ||
                    productOptionsError?.message ||
                    purchaserInfoError?.message ||
                    facilitiesError?.message}
                </Alert>
              </div>
            )}
            <div className="col-span-12 lg:col-span-3">
              <Aggregates
                aggregates={aggregates || []}
                loading={
                  fetchingLabelAggregates &&
                  fetchingProductAggregates &&
                  fetchingOrderTypeAggregates &&
                  fetchingCorporateOrderAggregates &&
                  fetchingAuxiliaryAggregates
                }
                toShipOn={toShipOn}
              />
            </div>
            <div className="col-span-12 lg:col-span-9">
              <div className="flex flex-col gap-2 justify-between xl:flex-row mb-4">
                <div className="center-v lg:w-60">
                  <SimpleSearch
                    disabled={!( hasPackages || tableSearchValue ) || fetchingPackages}
                    defaultValue={tableSearchValue}
                    handleSubmit={( value: string ) => setTableSearchValue( value )}
                    handleClear={() => setTableSearchValue( '' )}
                    asStandaloneForm
                  />
                </div>
                {packagesContextValue && (
                  <PackagesContext.Provider value={packagesContextValue}>
                    <ManifestActions
                      selectedPackages={selectedPackages}
                      facilityAggregates={facilityAggregates || []}
                      disabled={fetchingFacilityAggregates || fetchingPackages}
                      handleAssignmentDone={resetManifest}
                    />
                  </PackagesContext.Provider>
                )}
              </div>
              {fetchingPackages && !hasPackages && <Skeleton height="50vh" width="100%" />}
              {!( hasPackages || fetchingPackages ) && (
                <div data-testid="alert-no-orders">
                  <Alert type="info">No orders found</Alert>
                </div>
              )}
              {hasPackages && (
                <div className="relative">
                  {fetchingPackages && (
                    <div className="absolute inset-0 bg-white opacity-50 z-20" />
                  )}
                  <div className="flex justify-between items-center mb-4">
                    <button
                      data-testid="package-count"
                      type="button"
                      className="text-sm font-semibold flex"
                      onClick={() => {
                        navigator.clipboard.writeText( copyablePackageIds )
                        setIsCopied( true )
                        setTimeout(() => setIsCopied( false ), 3000 )
                      }}
                    >
                      {`${selectedPackages.size} selected | ${totalOrders} total orders`}

                      {isCopied && (
                        <div className="text-gb-blue-600">
                          <FontAwesomeIcon icon={faCheck} className="pl-2 pr-1" />
                          Package IDs Copied
                        </div>
                      )}
                    </button>
                    <ResultsPicker
                      localStorageKey={localStorageKey}
                      defaultValue={localStorageDefault}
                      disabled={fetchingPackages}
                    />
                  </div>
                  <PackageListTable
                    packages={packages}
                    orderTypes={orderTypes}
                    corporateOrders={corporateOrders}
                    productOptions={manifestProductOptions?.lineItemsCustomerChoices}
                    purchaserInfo={
                      canQueryPurchaserInfo ? manifestPurchaserInfo?.purchaserInfo : undefined
                    }
                    facilities={facilities}
                    fetchingOrderTypes={fetchingOrderTypes}
                    fetchingCorporateOrders={fetchingCorporateOrders}
                    fetchingProductOptions={fetchingProductOptions}
                    fetchingPurchaserInfo={fetchingPurchaserInfo}
                    fetchingFacilities={fetchingFacilities}
                    isAbstractFacility={isAbstractFacility}
                    canAssignLocation={canAssignLocation}
                    packagesAreSelectable
                    selectedPackages={new Set( Array.from( selectedPackages ))}
                    sortAction={( field: string, ascending: boolean ) =>
                      setPackageSort({
                        sortField: field as IManifestPackageSort,
                        sortAscending: ascending,
                      })
                    }
                    sortValue={packageSort || manifestPackages.manifestPackages?.metadata?.sorter}
                    selectAllRef={selectAllRef}
                    selectAllHandleChange={( e ) => {
                      if ( e.currentTarget.checked ) {
                        selectPackages( eligiblePackages.map(({ id }) => id ))
                      } else {
                        deselectAllPackages()
                      }
                    }}
                    selectPackageChange={( e: ChangeEvent<HTMLInputElement>, id: string ) =>
                      onChange( e, id )
                    }
                    tableFooterChildren={
                      <div className="w-full flex justify-between">
                        <ResultsPicker
                          localStorageKey={localStorageKey}
                          defaultValue={localStorageDefault}
                          disabled={fetchingPackages}
                        />
                        {pageCount > 1 && (
                          <Pager
                            pageCount={pageCount}
                            onPageChange={({ selected }) => setPage( selected )}
                            forcePage={page}
                            data-testid="manifest-pager"
                          />
                        )}
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          </Grid>
        </Container>
      </FormProvider>
    </>
  )
}

export default Manifest
