import { faChevronLeft, faChevronRight, faRefresh } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { FC } from 'react'
import { To } from 'react-router-dom'
import Button from 'src/components/01-atoms/Button'

interface ICalendarNavProps {
  prevLink: To
  todayLink: To
  nextLink: To
  className?: string
  showRefresh?: boolean
  isFetching?: boolean
  onRefresh?: () => void
}

const CalendarNav: FC<ICalendarNavProps> = ({
  prevLink,
  todayLink,
  nextLink,
  className,
  showRefresh = false,
  isFetching,
  onRefresh,
}) => (
  <div className={classNames( 'flex flex-row gap-x-2 lg:gap-x-4', className )}>
    {showRefresh && (
      <button
        type="button"
        className={classNames( 'flex items-center gap-1 text-xs', {
          'text-gb-blue-600 bold text underline': !isFetching,
        })}
        disabled={isFetching}
        onClick={onRefresh}
      >
        <FontAwesomeIcon icon={faRefresh} className={classNames({ 'animate-spin': isFetching })} />
        <div className="font-semibold">{isFetching ? 'Refreshing...' : 'Refresh Calendar'}</div>
      </button>
    )}
    <Button
      data-testid="calendar-nav-previous"
      className="gap-x-2 center w-8 pl-3 pr-3 lg:w-auto lg:pl-4 lg:pr-4"
      size="small"
      to={prevLink}
      outline
    >
      <FontAwesomeIcon icon={faChevronLeft} />
      <div className="sr-only lg:not-sr-only">Previous</div>
    </Button>
    <Button
      data-testid="calendar-nav-today"
      className="h-8 pl-2 pr-2 center lg:h-auto lg:pl-4 lg:pr-4"
      size="small"
      to={todayLink}
      outline
    >
      Today
    </Button>
    <Button
      data-testid="calendar-nav-next"
      className="gap-x-2 center w-8 pl-3 pr-3 lg:w-auto lg:pl-4 lg:pr-4"
      size="small"
      to={nextLink}
      outline
    >
      <div className="sr-only lg:not-sr-only">Next</div>
      <FontAwesomeIcon icon={faChevronRight} />
    </Button>
  </div>
)

export default CalendarNav
