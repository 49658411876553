import { FC, useState } from 'react'

import Button from 'src/components/01-atoms/Button'
import Grid from 'src/components/01-atoms/Grid'
import TextInput, { ITextInputProps } from 'src/components/01-atoms/TextInput'

import { FormTextInputProps } from 'src/utils/types/forms'

interface IPurchaserInfoForm {
  /**
   * Props for the email input element, likely from `react-hook-form` register method, but can be an object of string key => value pairs.
   */
  fieldProps?: {
    firstName?: FormTextInputProps & Partial<ITextInputProps>
    lastName?: FormTextInputProps & Partial<ITextInputProps>
    phone?: FormTextInputProps & Partial<ITextInputProps>
    email?: FormTextInputProps & Partial<ITextInputProps>
  }

  /**
   * Whether or not the form is a standalone form.
   */
  isStandaloneForm?: boolean
}

const PurchaserInfoForm: FC<IPurchaserInfoForm> = ({ fieldProps, isStandaloneForm }) => {
  const [ isEditing, setIsEditing ] = useState( false )
  const isEditable = !isStandaloneForm || isEditing

  return (
    <Grid className="gap-y-0">
      <TextInput
        wrapperClassName="col-span-12 md:col-span-6 xl:col-span-4"
        labelText="First Name *"
        name="firstName"
        showLabel
        outline
        reduceLayoutShift
        {...fieldProps?.firstName}
        disabled={!isEditable}
      />
      <TextInput
        wrapperClassName="col-span-12 md:col-span-6 xl:col-span-4"
        labelText="Last Name *"
        name="lastName"
        showLabel
        outline
        reduceLayoutShift
        {...fieldProps?.lastName}
        disabled={!isEditable}
      />
      <TextInput
        wrapperClassName="col-span-12 md:col-span-6 xl:col-span-4"
        type="tel"
        labelText="Phone *"
        name="phone"
        showLabel
        outline
        reduceLayoutShift
        maxLength={50}
        {...fieldProps?.phone}
        disabled={!isEditable}
      />
      <TextInput
        wrapperClassName="col-span-12 md:col-span-6 xl:col-span-4"
        type="email"
        labelText="Email *"
        name="email"
        helperText="Sends email confirmation and order information when the package ships."
        showLabel
        outline
        reduceLayoutShift
        {...fieldProps?.email}
        disabled={!isEditable}
      />
      {isStandaloneForm && (
        <div
          className={`col-span-12 sm:col-span-4 sm:col-start-5 xl:col-end-13 ${
            isEditing ? 'xl:col-span-4' : 'xl:col-span-2'
          }`}
        >
          <div className="flex gap-4 pt-6">
            {isEditing ? (
              <>
                <Button
                  type="reset"
                  className="center w-full"
                  data-testid="cancel-button"
                  onClick={() => setIsEditing( false )}
                  outline
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="center w-full"
                  data-testid="save-button"
                  onSubmit={() => setIsEditing( false )}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                type="button"
                className="center w-full"
                data-testid="edit-button"
                onClick={() => setIsEditing( true )}
                outline
              >
                Edit
              </Button>
            )}
          </div>
        </div>
      )}
    </Grid>
  )
}

export default PurchaserInfoForm
