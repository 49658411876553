import { useCallback } from 'react'
import { addDays, parseISO, startOfWeek } from 'date-fns'
import { useParams } from 'react-router-dom'
import useShippingIssueCalendarPair from './useShippingIssueCalendarPair'

const useWeeklyShippingIssueCalendarPair = () => {
  const { toShipOn } = useParams()
  const currentDate = toShipOn ? parseISO( toShipOn ) : new Date()
  const monday = startOfWeek( currentDate, { weekStartsOn: 1 })

  // react doesn't allow dynamic hooks, so we have to create a hook for each day of the week
  const [ mondayData, refetchMonday ] = useShippingIssueCalendarPair({ toShipOn: monday })
  const [ tuesdayData, refetchTuesday ] = useShippingIssueCalendarPair({
    toShipOn: addDays( monday, 1 ),
  })
  const [ wednesdayData, refetchWednesday ] = useShippingIssueCalendarPair({
    toShipOn: addDays( monday, 2 ),
  })
  const [ thursdayData, refetchThursday ] = useShippingIssueCalendarPair({
    toShipOn: addDays( monday, 3 ),
  })
  const [ fridayData, refetchFriday ] = useShippingIssueCalendarPair({ toShipOn: addDays( monday, 4 ) })
  const [ saturdayData, refetchSaturday ] = useShippingIssueCalendarPair({
    toShipOn: addDays( monday, 5 ),
  })

  const refetch = useCallback(() => {
    refetchMonday()
    refetchTuesday()
    refetchWednesday()
    refetchThursday()
    refetchFriday()
    refetchSaturday()
  }, [
    refetchMonday,
    refetchTuesday,
    refetchWednesday,
    refetchThursday,
    refetchFriday,
    refetchSaturday,
  ])

  return {
    weeklyData: [ mondayData, tuesdayData, wednesdayData, thursdayData, fridayData, saturdayData ],
    refetch,
  }
}

export default useWeeklyShippingIssueCalendarPair
